// font weights 300 (light), 400 (normal), 500 (medium), 700 (bold)
html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	line-height: 1.6em;
	letter-spacing: 1px;
}

html, body {
	color: color(#30343F tint(25%));
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-repeat: repeat-x;
}

div, header, footer, nav, section, aside, input {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color: $link-color;
}

// border-box for purecss grid
.pure-g > div {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.4em;
	font-weight: normal;
}

// remove focus outline
textarea:focus, input:focus{
	outline: none;
}

*:focus {
	outline: none;
}


h1, h2, h3, h4, a, p, li {
	opacity: 1;
	transition: opacity .3s ease-in-out;
}

.wf-loading {
	h1, h2, h3, h4, a, p, li {
		opacity: 0;
	}
}

::-moz-placeholder {}
:-ms-input-placeholder {}
::-webkit-input-placeholder {}

input:focus {
	&::-moz-placeholder {}
	&:-ms-input-placeholder {}
	&::-webkit-input-placeholder {}
}
