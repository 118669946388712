#banner {
	background-position: 0 0;
	background-size: cover;
	height: 28em;
	text-align: center;
	position: relative;
	opacity: 0;
	transition: all .7s ease;
}

#banner .container {
	white-space: nowrap;
	text-align: left;
	height: 100%;
	padding: 0 1rem;

	@media screen and (min-width: 1100px) {
		padding: 0;
	}
}

#banner .container > * {
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.25em; /* Adjusts for spacing */
	}
}

#banner .call-to-action {
	display: inline-block;
  	vertical-align: middle;
	max-width: 400px;
	white-space: normal;

	h1 {
		font-size: 40px;
		font-weight: 700;
		color: white;
		line-height: 1.2em;
		margin-bottom: .5em;
		text-shadow: 1px 1px 5px rgba(0,0,0,0.4);
	}

	h2 {
		font-size: 20px;
		font-weight: 700;
		font-size: 20px;
		color: white;
		font-weight: 400;
		line-height: 1.5em;
		margin-bottom: 1em;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	}
}

#banner header h2 {
	display: inline-block;
	margin: 0;
	font-size: 1.25em;
	vertical-align: middle;
}

#banner header h2 em {
	opacity: 0.75;
}

#banner header h2 a {
	border-bottom-color: rgba(255, 255, 255, 0.5);
}

#banner header h2 a:hover {
	border-bottom-color: transparent;
}

#banner header .button {
	vertical-align: middle;
	margin-left: 1em;
}
