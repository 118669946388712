.grid-layout {
	.card {
		margin: 0 1em;
		border-radius: 3px;
		background-color: #f7f7f7;
		margin-bottom: 2em;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  	transition: all 0.2s ease-in-out;
  	cursor: pointer;
  	overflow: hidden;

  	&:hover {
  		box-shadow: 0 4px 6px rgba(0,0,0,0.15), 0 3px 3px rgba(0,0,0,0.23);
  	}

  	.card-header {
  		max-height: 100px;
  		overflow: hidden;
  	}

  	.card-body {
  		padding: 10px;
  		box-sizing: border-box;
    	min-height: 160px;

  		h3 {
				font-size: 14px;

				a {
					text-decoration: none;
				}
  		}

			p {
				font-size: 14px;
			}
  	}
	}
}
