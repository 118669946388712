	.profile-photo {
		border-radius: 50%;
		margin: 1em auto;
		overflow: hidden;
		max-width: 200px;

		img {
			display: block;
			width: 100%;
		}
	}
