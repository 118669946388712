.column-list-layout {
	.post {
		margin: 0 1em;
		margin-bottom: 2em;

  	transition: all 0.2s ease-in-out;
  	cursor: pointer;
  	overflow: hidden;

  	.post-header {
  		width: 80%;
      margin: 0 auto;
  		overflow: hidden;
      border-radius: 50%;

      &:hover {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        // box-shadow: 0 4px 6px rgba(0,0,0,0.15), 0 3px 3px rgba(0,0,0,0.23);
      }

      img {
        width: 100%;
        display: block;
      }
  	}

  	.post-body {
  		padding: 10px;
  		box-sizing: border-box;
    	min-height: 160px;

  		h3 {
				font-size: 14px;
				margin-bottom: 5px;
				text-transform: uppercase;

				a {
					text-decoration: none;
				}
  		}

			.subtitle {
				font-size: 12px;
				font-weight: 500;
			}

			p {
				font-size: 14px;
			}
  	}
	}
}
