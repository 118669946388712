.container {
	max-width: 1100px;
	margin: 0 auto;

	@media #{$screen-xl}  {
		max-width: 1024px;
		margin: 0 auto;
	}
	@media (max-width: 768px)  {
		margin: 0 2em;
	}
}
