.list-layout {
	border-bottom: $thin-line;
	padding-bottom: 20px;
	margin-bottom: 20px;

	&:last-child {
		border-bottom: 0;
	}

	h2 {
		a {
			color: $font-special;
			text-decoration: none;
		}
		margin: 0;
	}

	div {
		font-size: .9em;
		line-height: 1.4em;

		p {
			margin: .6em auto;
		}
	}

	date {
		font-size: 12px;
		color: rgba($font-special, 0.3);
	}
}
