.pure-button {
	padding: .5em 1.5em;
}

.button-xsmall {
  font-size: 70%;
}

.button-small {
  font-size: 85%;
}

.button-large {
  font-size: 110%;
}

.button-xlarge {
  font-size: 100%;
  padding: 1em 3em;
}

.button-success,
.button-error,
.button-warning,
.button-secondary {
    color: white;
    border-radius: 4px;
    text-shadow: none;
}

.button-success {
    background: $brand-color; /* this is a green */
}

.button-error {
    background: rgb(202, 60, 60); /* this is a maroon */
}

.button-warning {
    background: rgb(223, 117, 20); /* this is an orange */
}

.button-secondary {
    background: rgb(66, 184, 221); /* this is a light blue */
}

.button-outline {
  background: transparent;
  border: 1px solid #999;
  color: #555;
  transition: all .2s ease;

  &:hover {
    background: $brand-color;
    color: white;
    border-color: $brand-color;
  }
}