.posts-list {
	list-style-type: none;
	padding: 0;

	li {
		margin: 20px 0 30px !important;

		h3 {
			margin-bottom: 0;
		}
		date	{
			font-size: 70%;
		}
		p {
			margin: 0;
		}
		a {
			text-decoration: none;
			font-weight: 500;
		}
	}
}
