// Base colors
$brand-color: #8b2004;
$font-dark: #061828;
$font-light: #eee;
$link-color: $brand-color;
$font-special: #1e405d;

// $brand-80-color: rgba($color-brand, 0.8);
// $accent-color: yellow;

$thin-line: 1px solid #eee;

// Media queries
$retina:"
	(min--moz-device-pixel-ratio: 1.3),
	(-o-min-device-pixel-ratio: 2.6/2),
	(-webkit-min-device-pixel-ratio: 1.3),
	(min-device-pixel-ratio: 1.3),
	(min-resolution: 1.3dppx)";


$screen-sm:"(max-width: 568px)"; //	.pure-u-sm-*
$screen-md:"(min-width: 568px) and (max-width: 768px)"; //	.pure-u-md-*
$screen-lg:"(min-width: 768px) and (max-width: 1024px)"; //.pure-u-lg-*
$screen-xl:"(min-width: 1024px) and (max-width: 1280px)"; //.pure-u-xl-*
$screen-xxl:"(min-width: 1280em)";

%flex-parent {
	display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;      /* TWEENER - IE 10 */
	display: -webkit-flex;     /* NEW - Chrome */
	display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
	align-items: center;
}

%flex-child {
	-webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: 1;         /* OLD - Firefox 19- */
	-webkit-flex: 1;          /* Chrome */
	-ms-flex: 1;              /* IE 10 */
	flex: 1;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}