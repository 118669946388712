.meta {
	overflow: hidden;

	.post-date {
		float: left;
		margin-right: 10px;
		font-size: 12px;
	}

	.meta-separator {
		float: left;
		margin-right: 10px;
	}

	.tags-list {
		float: left;
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			float: left;
			margin-right: 10px;
			margin-bottom: 0;
			line-height: 2em;
			font-size: 12px;

			a {
				font-size: 12px;
				text-decoration: none;
				display: inline-block;
				text-transform: capitalize;
			}
		}
	}
}
